const SELECTOR_BANNER_CONTAINER = '.banner-container';
const SELECTOR_LOGO_LABEL = '.banner-logo';
const SELECTOR_LABEL = '.banner-subline';
const SELECTOR_HEADLINE = '.banner-headline';
const SELECTOR_CTA = '.banner-text';
const SELECTOR_LINK = '.layered-link';
const SELECTOR_TEXT_CONTAINER = '.text-container';

const COOKIE_NAME = 'SBEKUKL';

const PROPERTY_CLUSTER = 'cluster';
const PROPERTY_DEFAULT_IMAGE_SRC = 'defaultImagesrc';
const PROPERTY_LOGO_LABEL_SRC = 'logoLabelSrc';
const PROPERTY_LABEL = 'label';
const PROPERTY_IMAGE_SRC = 'imageSrc';
const PROPERTY_TITLE = 'title';
const PROPERTY_CTA = 'cta';
const PROPERTY_LINKZIEL = 'linkZiel';
const PROPERTY_LINKTARGET = 'linkTarget';

const CLASS_HIDDEN = 'hidden';
const CLASS_INVISIBLE = 'invisible';

const PROPERTY_PREVIEW_CLUSTER = 'previewCluster';

class BannerFullsize extends HTMLElement {
  constructor() {
    super();
    /** saves the active clusters from cookie */
    this.activeClusters = [];
    /** saves the configured data-attributes from DOM */
    this.data = null;
  }

  connectedCallback() {
    this.setElements();
    this.extractDataFromDOM();
    if (this.hasConfiguredClusters()) {
      this.readActiveClustersFromCookie();
      this.modifyBannerWithClusterConfig();
      this.showBanner();
    }
  }

  setElements() {
    this.bannerContainer = this.querySelector(SELECTOR_BANNER_CONTAINER);
    this.logoLabelElement = this.querySelector(SELECTOR_LOGO_LABEL);
    this.labelElement = this.querySelector(SELECTOR_LABEL);
    this.titleElement = this.querySelector(SELECTOR_HEADLINE);
    this.callToActionElement = this.querySelector(SELECTOR_CTA);
    this.linkElement = this.querySelector(SELECTOR_LINK);
    this.textContainerElement = this.querySelector(SELECTOR_TEXT_CONTAINER);
  }

  extractDataFromDOM() {
    const dataset = this.bannerContainer.dataset;
    this.data = {};
    if (dataset && dataset.hasOwnProperty(PROPERTY_DEFAULT_IMAGE_SRC)) {
      this.data[PROPERTY_DEFAULT_IMAGE_SRC] = dataset[PROPERTY_DEFAULT_IMAGE_SRC];
    } else {
      return;
    }
    if (dataset.hasOwnProperty(PROPERTY_PREVIEW_CLUSTER)) {
      this.data[PROPERTY_PREVIEW_CLUSTER] = dataset[PROPERTY_PREVIEW_CLUSTER];
    }
    if (dataset.hasOwnProperty(PROPERTY_CLUSTER)) {
      const jsonObject = JSON.parse(dataset.cluster);
      if (jsonObject && jsonObject.hasOwnProperty(PROPERTY_CLUSTER)) {
        this.data.clusterList = jsonObject[PROPERTY_CLUSTER];
      } else {
        this.data.clusterList = [];
      }
    }
  }

  parseBase64(token) {
    try {
      return JSON.parse(atob(token));
    } catch (e) {
      return null;
    }
  }

  getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
      return parts.pop().split(';').shift();
    }
    return null;
  }

  readActiveClustersFromCookie() {
    const cookie = this.getCookie(COOKIE_NAME);
    if (this.data[PROPERTY_PREVIEW_CLUSTER]) {
      this.activeClusters = [this.data[PROPERTY_PREVIEW_CLUSTER]];
    } else if (cookie) {
      const jsonCookieValue = this.parseBase64(cookie);
      const activeClusterString = (jsonCookieValue && jsonCookieValue.cl) ? jsonCookieValue.cl : "";
      this.activeClusters = activeClusterString.split(',');
    }
  }

  modifyBannerWithClusterConfig() {
    if (this.activeClusters.length === 0) {
      this.useDefaultBanner();
      return;
    }
    let hasSpecificClusterBanner = false;
    this.data.clusterList.forEach((clusterConfig) => {
      if (!hasSpecificClusterBanner && this.activeClusters.indexOf(clusterConfig['kundencluster']) !== -1) {
        if (clusterConfig[PROPERTY_TITLE] || clusterConfig[PROPERTY_LABEL] || clusterConfig[PROPERTY_LOGO_LABEL_SRC] || clusterConfig[PROPERTY_CTA]) {
          this.setElementVisible(this.textContainerElement, true);
        } else {
          this.setElementVisible(this.textContainerElement, false);
        }
        this.setImageSrc(clusterConfig[PROPERTY_IMAGE_SRC]);
        this.setLabelOrLogoLabel(clusterConfig);
        this.setTitle(clusterConfig);
        this.setCallToAction(clusterConfig);
        this.setLink(clusterConfig);
        hasSpecificClusterBanner = true;
      }
    });
    if (!hasSpecificClusterBanner) {
      this.useDefaultBanner();
    }
  }

  hasConfiguredClusters() {
    return this.data.hasOwnProperty(PROPERTY_DEFAULT_IMAGE_SRC);
  }

  useDefaultBanner() {
    const imageSrc = this.data[PROPERTY_DEFAULT_IMAGE_SRC];
    this.setImageSrc(imageSrc);
  }

  setImageSrc(imageSrc) {
    this.bannerContainer.setAttribute('style', `background-image: url('${imageSrc}');`);
  }

  setLabelOrLogoLabel(clusterConfig) {
    if (clusterConfig[PROPERTY_LOGO_LABEL_SRC]) {
      this.logoLabelElement.setAttribute('src', clusterConfig[PROPERTY_LOGO_LABEL_SRC])
      this.setElementVisible(this.logoLabelElement, true);
      this.setElementVisible(this.labelElement, false);
    } else if (clusterConfig[PROPERTY_LABEL]) {
      this.labelElement.textContent = clusterConfig[PROPERTY_LABEL];
      this.setElementVisible(this.logoLabelElement, false);
      this.setElementVisible(this.labelElement, true);
    } else {
      this.setElementVisible(this.logoLabelElement, false);
      this.setElementVisible(this.labelElement, false);
    }
  }

  setTitle(clusterConfig) {
    this.setElementTextContent(clusterConfig, PROPERTY_TITLE, this.titleElement);
  }

  setCallToAction(clusterConfig) {
    this.setElementTextContent(clusterConfig, PROPERTY_CTA, this.callToActionElement);
  }

  setElementTextContent(clusterConfig, propertyName, element) {
    if (clusterConfig[propertyName]) {
      element.textContent = clusterConfig[propertyName];
      this.setElementVisible(element, true);
    } else {
      this.setElementVisible(element, false);
    }
  }

  setLink(clusterConfig) {
    if (clusterConfig[PROPERTY_LINKZIEL] && clusterConfig.hasOwnProperty(PROPERTY_LINKTARGET)) {
      this.linkElement.setAttribute('href', clusterConfig[PROPERTY_LINKZIEL]);
      this.linkElement.setAttribute('target', clusterConfig[PROPERTY_LINKTARGET]);
      this.linkElement.setAttribute('promotion-creative', `${this.linkElement.getAttribute('promotion-creative')}-${clusterConfig.kundencluster}`)
      this.setElementVisible(this.linkElement, true);
    } else {
      this.setElementVisible(this.linkElement, false);
    }
  }

  setElementVisible(element, visible) {
    if (visible) {
      element.classList.remove(CLASS_HIDDEN);
    } else {
      element.classList.add(CLASS_HIDDEN);
    }
  }

  showBanner() {
    this.bannerContainer.classList.remove(CLASS_INVISIBLE);
  }
}

if (!customElements.get("banner-fullsize")) {
  customElements.define("banner-fullsize", BannerFullsize);
}


